define("ember-loading/decorator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loading;

  function loading(_target, _propertyKey, desc) {
    var orig = desc.value;
    (false && !(typeof orig === 'function') && Ember.assert('The @loading decorator must be applied to methods', typeof orig === 'function'));

    desc.value = function () {
      var owner = Ember.getOwner(this);
      (false && !(!!owner) && Ember.assert('The target of the @loading decorator must have an owner.', !!owner));
      var service = owner.lookup('service:loading');
      return service.run.apply(service, [this, orig].concat(Array.prototype.slice.call(arguments)));
    };
  }

  ;
});