define("ember-currencies-and-format-money/currencies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currenciesArray = _exports.currenciesObject = void 0;
  var TRY = {
    name: 'lira',
    acronym: 'TRY',
    symbol: '₺',
    digitSeparator: '.',
    decimalSeparator: ',',
    currencyPlacement: 's',
    smallestUnitRatio: 100
  };
  var USD = {
    name: 'dollar',
    acronym: 'USD',
    symbol: '$',
    digitSeparator: ',',
    decimalSeparator: '.',
    currencyPlacement: 'p',
    smallestUnitRatio: 100
  };
  var AUD = {
    name: 'dollar',
    acronym: 'AUD',
    symbol: 'A$',
    digitSeparator: ',',
    decimalSeparator: '.',
    currencyPlacement: 'p',
    smallestUnitRatio: 100
  };
  var DKK = {
    name: 'krone',
    acronym: 'DKK',
    symbol: 'Dkr',
    digitSeparator: '.',
    decimalSeparator: ',',
    currencyPlacement: 'p',
    smallestUnitRatio: 100
  };
  var EUR = {
    name: 'euro',
    acronym: 'EUR',
    symbol: '€',
    digitSeparator: '.',
    decimalSeparator: ',',
    currencyPlacement: 's',
    smallestUnitRatio: 100
  };
  var GBP = {
    name: 'pound',
    acronym: 'GBP',
    symbol: '£',
    digitSeparator: ',',
    decimalSeparator: '.',
    currencyPlacement: 'p',
    smallestUnitRatio: 100
  };
  var CHF = {
    name: 'swiss franc',
    acronym: 'CHF',
    symbol: 'Fr.',
    digitSeparator: "'",
    decimalSeparator: '.',
    currencyPlacement: 's',
    smallestUnitRatio: 100
  };
  var SEK = {
    name: 'krona',
    acronym: 'SEK',
    symbol: 'Skr',
    digitSeparator: ' ',
    decimalSeparator: '.',
    currencyPlacement: 's',
    smallestUnitRatio: 100
  };
  var CAD = {
    name: 'dollar',
    acronym: 'CAD',
    symbol: 'C$',
    digitSeparator: ',',
    decimalSeparator: '.',
    currencyPlacement: 'p',
    smallestUnitRatio: 100
  };
  var KWD = {
    name: 'dinar',
    acronym: 'KWD',
    symbol: 'KWD',
    digitSeparator: ',',
    decimalSeparator: '.',
    currencyPlacement: 'p',
    smallestUnitRatio: 1000
  };
  var NOK = {
    name: 'krone',
    acronym: 'NOK',
    symbol: 'Nkr',
    digitSeparator: '.',
    decimalSeparator: ',',
    currencyPlacement: 'p',
    smallestUnitRatio: 100
  };
  var SAR = {
    name: 'riyal',
    acronym: 'SAR',
    symbol: '﷼',
    digitSeparator: ',',
    decimalSeparator: '.',
    currencyPlacement: 'p',
    smallestUnitRatio: 100
  };
  var BGN = {
    name: 'lev',
    acronym: 'BGN',
    symbol: 'лв',
    digitSeparator: ',',
    decimalSeparator: '.',
    currencyPlacement: 'p',
    smallestUnitRatio: 100
  };
  var RON = {
    name: 'leu',
    acronym: 'RON',
    symbol: 'lei',
    digitSeparator: ',',
    decimalSeparator: '.',
    currencyPlacement: 'p',
    smallestUnitRatio: 100
  };
  var RUB = {
    name: 'ruble',
    acronym: 'RUB',
    symbol: '₽',
    digitSeparator: ',',
    decimalSeparator: '.',
    currencyPlacement: 's',
    smallestUnitRatio: 100
  };
  var IRR = {
    name: 'rial',
    acronym: 'IRR',
    symbol: '﷼',
    digitSeparator: ',',
    decimalSeparator: '.',
    currencyPlacement: 'p',
    smallestUnitRatio: 100
  };
  var CNY = {
    name: 'yuan',
    acronym: 'CNY',
    symbol: 'C¥',
    digitSeparator: ',',
    decimalSeparator: '.',
    currencyPlacement: 'p',
    smallestUnitRatio: 100
  };
  var PKR = {
    name: 'rupee',
    acronym: 'PKR',
    symbol: '₨',
    digitSeparator: ',',
    decimalSeparator: '.',
    currencyPlacement: 'p',
    smallestUnitRatio: 100
  };
  var JPY = {
    name: 'yen',
    acronym: 'JPY',
    symbol: 'J¥',
    digitSeparator: ',',
    decimalSeparator: '.',
    currencyPlacement: 'p',
    smallestUnitRatio: 100
  };
  var currenciesObject = {
    TRY: TRY,
    USD: USD,
    AUD: AUD,
    DKK: DKK,
    EUR: EUR,
    GBP: GBP,
    CHF: CHF,
    SEK: SEK,
    CAD: CAD,
    KWD: KWD,
    NOK: NOK,
    SAR: SAR,
    BGN: BGN,
    RON: RON,
    RUB: RUB,
    IRR: IRR,
    CNY: CNY,
    PKR: PKR,
    JPY: JPY
  };
  _exports.currenciesObject = currenciesObject;
  var currenciesArray = Object.keys(currenciesObject).map(function (k) {
    return currenciesObject[k];
  });
  _exports.currenciesArray = currenciesArray;
});