define("ember-currencies-and-format-money/helpers/format-money", ["exports", "ember-currencies-and-format-money/format-amount"], function (_exports, _formatAmount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatMoney = formatMoney;
  _exports.default = void 0;
  var DEFAULT_CURRENCY = 'TRY';

  function formatMoney(params, namedArgs) {
    var moneyValue = params[0];

    if (Ember.isBlank(moneyValue)) {
      return undefined;
    }

    var currencyHidden = null;

    if (namedArgs) {
      currencyHidden = namedArgs.currencyHidden;
    }

    var amount = moneyValue.amount;
    var currencyValue = moneyValue.currency;

    if (Ember.isBlank(currencyValue)) {
      currencyValue = DEFAULT_CURRENCY;
    }

    var amountFormatted = (0, _formatAmount.formatMoneyAmountByCurrency)(amount, currencyValue, currencyHidden);
    return amountFormatted;
  }

  var _default = Ember.Helper.helper(formatMoney);

  _exports.default = _default;
});